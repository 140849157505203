.myButton {
  background-color: var(--color-light-blue);
  border-radius: 0.5rem;
  padding: 0.55rem 1.9rem;
  font-weight: 600;
  /* max-width: 11rem; */
  height: 3rem;
  border: 2px solid var(--color-light-blue);
}

.myButton:hover {
  background-color: var(--color-white);
  color: var(--color-blue);
  border: 2px solid var(--color-light-blue);
}

.myButton:active,
.myButton:focus {
  box-shadow: none !important;
  background-color: var(--color-light-blue) !important;
  border: 2px solid var(--color-light-blue) !important;
  color: var(--color-white);
}

/* DARK */

.buttonDark {
  background-color: var(--color-dark-blue);
  border: 2px solid var(--color-dark-blue);
}

.buttonDark:hover {
  background-color: transparent;
  color: var(--color-dark-blue);
  border: 2px solid var(--color-dark-blue);
}

.buttonDark:active,
.buttonDark:focus {
  box-shadow: none !important;
  background-color: var(--color-dark-blue) !important;
  border: 2px solid var(--color-dark-blue) !important;
  color: var(--color-white);
}
