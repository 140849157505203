.mainDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.dotDiv {
    width: 7px;
    height: 7px;
    margin: 5px;
    border-radius: 100%;
}

@media only screen and (max-width: 615px) {
    .mainDiv {
      display: none;
    }
  }